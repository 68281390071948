document.addEventListener('DOMContentLoaded', function () {
  const item = document.querySelector('.simple-file-upload')
  if (item) {
    // insert span before
    const span = document.createElement('span')
    span.classList.add('simple-file-upload__button', 'text-truncate')
    span.innerText = item.value ? item.value.split('/').pop() : 'Dodaj paragon'
    item.parentNode.insertBefore(span, item)
    item.closest('.form-group').classList.add('position-relative')
    item.addEventListener('fileUploadStarted', function (e) {
      const error = item.parentNode.querySelector('.invalid-feedback-ajax')
      if (error) {
        error.remove()
      }
      span.innerText = 'Trwa wgrywanie...'
      span.classList.remove('is-invalid', 'is-valid')
    })
    item.addEventListener('fileUploadSuccess', function (e) {
      span.innerText = this.value.split('/').pop()
      span.classList.add('is-valid')
    })
    item.addEventListener('fileUploadFailed', function (e) {
      span.classList.add('is-invalid')
      span.innerText = 'DODAJ PARAGON'
      const error = document.createElement('div')
      error.classList.add('invalid-feedback', 'invalid-feedback-ajax', 'd-block')
      console.error(e.detail)
      error.innerText = e.detail.error
        .replace(
          /File is too big \(([^\)]+)\)\. Max filesize: ([^\.]+)\./is,
          'Plik jest za duży ($1). Maksymalny rozmiar pliku: $2.'
        )
        .replace(
          'You can\'t upload files of this type.',
          'Nie można przesłać plików tego typu.'
        )
      item.parentNode.appendChild(error)
    })
    // setTimeout(() => {
    //   item.dispatchEvent(new CustomEvent('fileUploadFailed', {
    //     detail: {
    //       error: 'Błąd przesyłania pliku'
    //     }
    //   }))
    // }, 2000)
    // setTimeout(() => {
    //   item.dispatchEvent(new CustomEvent('fileUploadStarted', {
    //     detail: {
    //       error: 'Błąd przesyłania pliku'
    //     }
    //   }))
    // }, 4000)
    // setTimeout(() => {
    //   item.value = 'https://promocja7days.pl/build/images/landing-products.4e2d092a.png';
    //   item.dispatchEvent(new CustomEvent('fileUploadSuccess', {
    //     detail: {
    //       error: 'Błąd przesyłania pliku'
    //     }
    //   }))
    // }, 6000)
  }
})
