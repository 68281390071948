// import { setRegisteredData } from "./forms/registered-data";
//
// $('[name="personal_data[billType]"]').on('change', function() {
//     $('[name="personal_data[billPos]"]', this.form.elements).closest('.form-group').prop('disabled', $(this).val() != 'Paragon');
// }).trigger('change');
//
// $('[name="personal_data[emailRepeat]"]').each(function() {
//     let $email = $('[name="personal_data[email]"]', this.form.elements);
//     let $emailRepeat = $('[name="personal_data[emailRepeat]"]', this.form.elements);
//     $($email).add($emailRepeat).on('input', function() {
//         $emailRepeat.toggleValid($email.val() == $emailRepeat.val());
//     }).trigger('change');
// });

$('.application-form').on('submit-success', function(event, data) {
    $('footer.footer-primary').css({
        'display': 'none !important',
        'position': 'absolute',
        'bottom': '0',
        'left': '-10000px',
    })
    $('section').addClass('d-none').removeClass('d-md-flex');
    $('section#thank-you').removeClass('d-none');
    $(window).scrollTop(0);
});
//
// $('.game-run').on('click', function() {
//     // page swap
//     $('.page').addClass('d-none');
//     $('.page-result').removeClass('d-none');
//     $('.page-result').css('display', 'block');
//
//     // modal (uncomment if you need modal instead of page swap)
//     //$('#modal-form-application').modal('show');
// });
//
// $('.result-try-again').on('click', function() {
//     $('.page').addClass('d-none');
//     $('.page-form').removeClass('d-none');
// });
//
// $('[name="personal_data[noPesel]"]').on('change', function() {
//     $('[name="personal_data[pesel]"]', this.form.elements).prop('disabled', $(this).prop('checked'));
//     let fields = [
//         '[name="personal_data[street]"]',
//         '[name="personal_data[streetNr]"]',
//         '[name="personal_data[flatNr]"]',
//         '[name="personal_data[zipCode]"]',
//         '[name="personal_data[city]"]',
//     ];
//     $(fields.join(', '), this.form.elements).closest('.form-group').prop('disabled', !$(this).prop('checked'));
// }).trigger('change');
//
// $('.winner-form').on('submit-success', function(event, data) {
//     setRegisteredData(data, 'section#winner');
//
//     // page swap
//     $('.page').addClass('d-none');
//     $('.page-thank-you').removeClass('d-none');
//     $('.page-thank-you').css('display', 'block');
//
//     // modal (uncomment if you need modal instead of page swap)
//     //$('#modal-form-winner').modal('show');
// });
//
// $('.contact-form').on('submit-success', function(event, data) {
//     setRegisteredData(data, 'section#contact');
//
//     // page swap
//     $('.page').addClass('d-none');
//     $('.page-thank-you').removeClass('d-none');
//     $('.page-thank-you').css('display', 'block');
//
//     // modal (uncomment if you need modal instead of page swap)
//     //$('#modal-form-contact').modal('show');
// });
