import $2 from "jquery";
window.$2 = $2;
import "select2/dist/js/select2.full.min"
import "select2/dist/css/select2.min.css"

$(document).ready(function() {
  $('#city').on('change', function() {
    const $option = $('#city').find(':selected').data('options') || [];
    if ($option.length === 0) {
      return '<option></option>';
    }
    let options = '<option></option>';
    $option.forEach(function(option) {
      options += `<option data-html="${option}">${option}</option>`;
    });
    $('#shop').prop('disabled', false).html(options).trigger('change');
  }).trigger('change');

  const template = data => data.element?.dataset?.html ? $(data.element.dataset.html) : data.text;

  $('.select2').select2({
    minimumResultsForSearch: Infinity,
    allowClear: false,
    templateResult: template,
    templateSelection: template,
  });

});

