import $2 from "jquery";
window.$2 = $2;
import 'simplebar/dist/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick';

"use strict";

$(document).ready(function () {
  $('.slider--howto').on('init', function(event, slick) {
    $('.slider--howto img').css({
      'cursor': 'pointer'
    }).off('click').on('click', function () {
      $('.slider--howto').slick('slickNext');
    })
  })
  $('.s-drinks .slider').on('init', function(event, slick) {
    $('.s-drinks .slider .drink__image').css({
      'cursor': 'pointer'
    }).off('click').on('click', function () {
      $('.s-drinks .slider').slick('slickNext');
    })
  })
  $('[data-slick]').slick();
});
